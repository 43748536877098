<template>
  <CommonPage id="home" title="The Book of Answer" classs="home">
    <section class="home-content pc_padding">
      <div class="banner">
        <div class="home-banner-txt">
          Mentally repeat your question and meditate with your eyes closed for 5 seconds <br>
          Then click on the Book of Answers
        </div>

        <div class="svgaPage">
          <div id="svga" />
        </div>

        <img v-show="!isPlaying" src="@/assets/book_pc.png" class="book" alt=""
          @click="getAnswer()">
        <div v-show="!isPlaying" class="mouse-hand" />

        <img src="@/assets/home-banner.png" alt="">
        <div class="answer-btn" @click="getAnswer()">
          Click to view answer
        </div>
      </div>

      <div class="home-intro-row">
        <AdComponent ref="ads-home-1" class="m-b-40" :ads="adsensConfig.home_1"
          :showDebug="showDebug" />
        <div class="home-intro-title">
          What's the Book of Answers?
        </div>
        <div class="home-intro-content">
          When you encounter something you can't deci de on, the Answer Book Online can provide yo u
          with a solution. Simply click on the Answer B ook to quickly get an answer.<br>
          <br>
          Each time you click on the Answer Book, it will give you an answer. Although the answer
          may not fully match your question or expectations, it can serve as a way of
          self-reflection, helping you think and solve problems from different p erspectives.<br>
          <br>
          We hope that the Answer Book can bring you inspiration and enjoyment in life, whether you
          are contemplating a question, making a decis ion, or simply using it for entertainment.
        </div>

        <AdComponent ref="ads-home-2" class="m-b-40" :ads="adsensConfig.home_2"
          :showDebug="showDebug" />

        <div class="home-intro-title">
          How to use this book?
        </div>
        <div class="home-intro-content">
          If you encounter something you can't decide on, you can let the Answer Book Online help
          you with just a few simple steps!<br>
          Silently think of a specific question in your mind. <br>
          <br><br>
          Close your eyes and focus your attention.<br>
          Gently click on the Answer Book Online.<br>
          Read the answer you see and ponder its meaning.
        </div>

        <AdComponent ref="ads-home-3" class="m-b-40" :ads="adsensConfig.home_3"
          :showDebug="showDebug" />

        <div class="home-intro-title">
          Q&A
        </div>
        <div class="home-intro-content ">
          <div class="qa-card">
            <div v-for="(item, index) in questions" :key="index" class="qa-item">
              <button class="qa-question" @click="toggleAnswer(index)">
                <span class="question-text">{{ item.question }}</span>
                <span class="arrow" :class="{ open: item.open }" />
              </button>
              <div v-if="item.open" class="qa-answer">
                {{ item.answer }}
              </div>
            </div>
          </div>
        </div>

        <AdComponent ref="ads-home-4" class="m-b-40" :ads="adsensConfig.home_4"
          :showDebug="showDebug" />

        <div class="home-intro-title">
          Something may help
        </div>
        <div class="home-intro-content last">
          <div class="time-page-card-row">
            <div v-for="(data,i) in card" :key="i" class="time-page-card-item"
              @click="goto(data.routename)">
              <div class="time-page-card-image">
                <img :src="data.img" :alt="data.title">
              </div>
              <div class="time-page-card-content">
                <div class="time-page-card-title">
                  {{ data.title }}
                </div>
                <div class="time-page-card-desc">
                  {{ data.desc }}
                </div>
              </div>
              <div class="time-page-card-btn">
                Read more
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </CommonPage>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import CommonPage from '@/pages/components/pc_common.vue';
import AdComponent from '@/pages/components/AdComponent.vue'

import '@/css/home.scss';

export default {
  name: 'HomeMain',
  components: {
    CommonPage,
    AdComponent
  },
  mixins: [common],
  data () {
    return {
      isPlaying: false, // 播放状态
      questions: [
        {
          question: 'Can the Answer Book Online be trusted?',
          answer:
            'Online AnswersBook is intended for entertainment and inspiration only and should not be considered professional advice or a basis for decision-making. Use it rationally and do not rely excessively on AnswersBook.',
          open: false,
        },
        {
          question: 'Is the Answersbook.online suitable for all questions?',
          answer: 'Answersbook.online is suitable for general questions but may not cover specific or detailed inquiries.',
          open: false,
        },
        {
          question: 'What is the relationship between the online version and the paper version of the Answer Book?',
          answer: 'The online version offers similar content to the paper version but may have updates or variations.',
          open: false,
        },
        {
          question: 'Are the provided answers completely accurate?',
          answer: 'The answers are not guaranteed to be completely accurate and should be verified if used for important decisions.',
          open: false,
        },
        {
          question: 'What should I do when I encounter a problem that I can’t make a decision on?',
          answer: 'Consider seeking professional advice or consulting reliable sources when making critical decisions.',
          open: false,
        },
      ],
      card: [
        {
          img: require('@/assets/NWI1NDQxNDgwYjdiMDYwMjU2NDA5MWU2ZjI3ODEwMGVfQUZlNkc2ZHI3Z2UyUDhkWVV4N0lJcnI3T1UyTUN3N2VfVG9rZW46VzBhWWJxVU9pbzRqSHB4RFBjYmNPY3d0bnlnXzE3MjYyMTExNDY6MTcyNjIxNDc0Nl9WNA.jpg'),
          title: 'Open the Book of Answers to Resolve Your Doubts',
          desc: "In our fast-paced lives, we often face the dilemma of making choices. The author has thoughtfully summarized the following excerpts from the Book of Answers, hoping that they will guide you to find your life's North Star. This article is especially for new university students.",
          routename: 'blog1'
        },
        {
          img: require('@/assets/YmMwMGNmNWE5NzA3OTgxMDQyNmRiMDBkNDMxNzc2NTZfc3BFRmpVQUtSSlllNkhHZmpDUlJPdnI4ZVZJR0hlMEpfVG9rZW46UGhzU2I2dTY1b0N0Nzh4WmRYSGNlZ3Nibk9nXzE3MjYyMTE2NzE6MTcyNjIxNTI3MV9WNA.webp'),
          title: "You Think It's Normal, But It Could Be Long-Term Anxiety: 9 Signs",
          desc: "Anxiety is an emotion everyone experiences, but few realize that anxiety disorders can severely impact one's life. This psychological condition, commonly known as anxiety disorder, can be hard to detect even by those suffering from it.",
          routename: 'blog2'
        },
        {
          img: require('@/assets/YTVmYTc3NWNmZWQzNTIyNTI0MjU2OTQ5OTE5ZWU4YjBfRHFzMHFPS21Yb3VJZUVqM1dOdmJNeXlhbTh3QVlGRzRfVG9rZW46THo5Z2JUdnlsb2xSVWl4aHZlQWM2YW8wbk9nXzE3MjYyMTIwMzI6MTcyNjIxNTYzMl9WNA.jpg'),
          title: 'Three Steps to Cure Your Decision Paralysis',
          desc: 'Struggling to make a choice between two options, always worrying about missing out or making a mistake, constantly questioning potential risks, and seeking a perfect solution—these are all signs of decision paralysis. Procrastinating until the last moment and feeling exhausted from overthinking can lead to missed opportunities or leaving the decision to someone else.',
          routename: 'blog3'
        },
        {
          img: require('@/assets/YWM5YTEwNjc1M2ZlODgxY2E0NjAyZTBiYzZhYjdiZjdfWUEzWnp5ZHo0eXhxVWFoVTkwTjNzS25kcmMwYWhpZ2NfVG9rZW46WTZWMWJlbDhRb3ZDSXJ4bXg3cmNheXIxbkJoXzE3MjYyMTIxNTk6MTcyNjIxNTc1OV9WNA.jpg'),
          title: 'Stress Management Guide: 5 Scientific Methods to Help You Relieve Stress...',
          desc: 'Transitioning from “busy” to “efficient” involves adopting systematic time management methods and committing to ongoing practice. By setting clear goals, creating detailed plans, prioritizing tasks, overcoming procrastination, managing distractions, adjusting regularly, prioritizing rest, using technological tools, developing good habits, and seeking support, you can significantly improve productivity in both work and life. Mastering these techniques will help you achieve greater organization and efficiency in your daily routines.', routename: 'blog4'
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "adsensConfig"
    ])
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
    await this.loadAdSenseScript();  // 重新加载广告脚本

  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    this.preloadSVGA(1);
    await this.loadAdSenseScript();  // 重新加载广告脚本

    // await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.observeAds(); // 监听广告元素进入可视区域
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },

    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        this.observeAds(); // 监听广告元素进入可视区域
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      script.onload = this.observeAds.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');
    },

    observeAds () {

      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-'))
        .flatMap(([, ref]) => ref);

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // 当广告元素进入可视区域时加载广告
            console.log('Ad element is in view:', entry.target);
            this.loadAdWithDelay(entry.target);
            observer.unobserve(entry.target); // 加载后停止观察该广告元素
          }
        });
      });

      adsElements.forEach(ad => {
        const adElement = ad instanceof HTMLElement ? ad : ad.$el;  // 如果是 Vue 实例，获取它的 $el
        if (adElement) {
          observer.observe(adElement); // 开始监听广告元素是否进入可视区域
          console.log('Observing ad element:', adElement);
        }
      });
    },

    loadAdWithDelay (adElement) {
      setTimeout(() => {
        this.displayAd(adElement);
      }, 1500);
    },

    async displayAd (adElement) {
      await this.$nextTick();

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(() => this.displayAd(adElement), 500); // 延迟再次尝试
        return;
      }

      console.log('Ad element is ready for push:', adElement);
      if (adElement.offsetWidth > 0) {
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } else {
        console.log('Ad element has no size, skipping.');
      }
    },
    async playSvga () {
      await this.loadAndPlaySVGA();
    },
    async loadAndPlaySVGA () {
      try {
        const videoItem = await this.preloadSVGA();
        const player = this.createSVGAPlayer();
        player.loops = 1;
        player.setVideoItem(videoItem);
        player.startAnimation();
        this.isPlaying = true; // 设置为播放状态
        console.log('start');
        // 监听播放完成事件
        player.onFinished(() => {
          this.isPlaying = false; // 播放结束时设置状态
          console.log('done');
          this.$router.push({ name: 'answer' });
        });
      } catch (error) {
        console.error("SVGA加载失败:", error);
      }
    },
    preloadSVGA () {
      // const url = "https://ptc-dressup-sg2.obs.ap-southeast-3.myhuaweicloud.com/rest/test/20231214/send.svga"
      // const url = `${process.env.VUE_APP_SVGA_URL}/svga/animate.svga`
      const url = '/animate.svga'
      const cacheKey = "createCache"
      const SVGA = require("svgaplayerweb");

      return new Promise((resolve, reject) => {
        if (this[cacheKey]) {
          resolve(this[cacheKey]);
        } else {
          const parser = new SVGA.Parser();
          parser.load(
            url,
            (videoItem) => {
              this[cacheKey] = videoItem;
              resolve(videoItem);
            },
            reject
          );
        }
      });
    },
    createSVGAPlayer () {
      const SVGA = require("svgaplayerweb");

      const elementId = "#svga";
      return new SVGA.Player(elementId);
    },
    toggleAnswer (index) {
      this.questions[index].open = !this.questions[index].open;
    },
    getAnswer () {
      // this.$router.push({ name: 'answer' });
      this.playSvga(1);

    },
    goto (name) {
      this.$router.push({ name: name });
    },
  }
}
</script>
